import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useState } from 'react';

const MenuBurger = () => {

  return (
    <div id='containerBurger'>
      <div id='LetsTalk'>
        Want to get in touch?
      </div>
      <div id='IconsContainer'>
        <div>
          <a href='https://github.com/Irakli0007' target='_blank' rel='noreferrer'>
            <i className='fab fa-github fa-3x GitHubBtn'></i>
          </a>
        </div>      
        <div>
          <a href='mailto:iraklig0007@gmail.com' target='_blank' rel='noreferrer'>
            <i className='fas fa-envelope fa-3x EmailBtn'></i>
          </a>
        </div>     
        <div>
          <a href='https://www.linkedin.com/in/iraklig/' target='_blank' rel='noreferrer'>
            <i className='fab fa-linkedin fa-3x LinkedInBtn'></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default MenuBurger;