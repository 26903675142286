import './skills.css'
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import gitIcon from "../../assets/git.png";
import vuejs from "../../assets/vuejs.png";
import react from "../../assets/react.png";
import dotnet from "../../assets/dotnet.png";
import mssql from "../../assets/mssql.png";
import electron from "../../assets/electron.png";
import mongodb from "../../assets/mongodb.png";
import java from "../../assets/java.png";
import python from "../../assets/python.jpg";
import rust from "../../assets/rust.png";
import flutter from "../../assets/flutter.png";
import javascript from "../../assets/javascript.png";
import csharp from "../../assets/csharp.png";
import golang from "../../assets/golang.png";
import jenkins from "../../assets/jenkins.jpg";

import Image from "react-bootstrap/Image";

const Skills = () => {
  return (
    <div>
      <h1>Skills</h1>
      <Row style={{width: "100%"}}>
        <Col>
          <Row>
            <Card className='FocusCard'>
              <Card.Body>
                <Card.Title className='card-title'>Version Control</Card.Title>
                <hr />
                <Card.Text className='card-text'>
                  <Image className='image-style' src={gitIcon} />
                  <div>Git</div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card className='FocusCard'>
              <Card.Body>
                <Card.Title className='card-title'>Currently Learning</Card.Title>
                <hr />
                <Card.Text className='card-text'>
                  <Image className='image-style' src={golang} />
                  <div>Golang</div>
                </Card.Text>
                <Card.Text className='card-text'>
                  <Image className='image-style' src={rust} />
                  <div>Rust</div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Col>
        <Col>
          <Card className='FocusCard'>
            <Card.Body>
              <Card.Title>Frameworks</Card.Title>
              <hr />
              <Card.Text>
                <Image className='image-style' src={dotnet} />
                <div>.Net</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={vuejs} />
                <div>Vue.js</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={react} />
                <div>React</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={electron} />
                <div>Electron</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={flutter} />
                <div>Flutter</div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Row>
            <Card className='FocusCard'>
              <Card.Body>
                <Card.Title>Databases</Card.Title>
                <hr />
                <Card.Text>
                  <Image className='image-style' src={mssql} />
                  <div>Microsoft SQL Server</div>
                </Card.Text>
                <Card.Text>
                  <Image className='image-style' src={mongodb} />
                  <div>MongoDB</div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Card className='FocusCard'>
              <Card.Body>
                <Card.Title>CI/CD</Card.Title>
                <hr />
                <Card.Text>
                  <Image className='image-style' src={jenkins} />
                  <div>Jenkins</div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </Col>
        <Col>
          <Card className='FocusCard'>
            <Card.Body>
              <Card.Title>Languages</Card.Title>
              <hr />
              <Card.Text>
                <Image className='image-style' src={csharp} />
                <div>C#</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={java} />
                <div>Java</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={python} />
                <div>Python</div>
              </Card.Text>
              <Card.Text>
                <Image className='image-style' src={javascript} />
                <div>JavaScript</div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>      
    </div>
  )
}

export default Skills;