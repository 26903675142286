import RowBurger from './RowBurger';
import { useState } from 'react';

const Hamburger = (props) => {
  const [click,setClick] = useState(false);

  const handleClick = () => {
    let containerBurger = document.querySelector("#containerBurger");
    containerBurger.style.willChange = 'clip-path';
    containerBurger.classList.toggle('expanded');
    document.getElementById("Hamburger").classList.toggle("hamburgerFixed");
    document.getElementsByClassName("firstRow")[0].classList.toggle("firstRowDisplay");
    document.getElementsByClassName("secondRow")[0].classList.toggle("secondRowDisplay");
    document.getElementsByClassName("thirdRow")[0].classList.toggle("thirdRowDisplay");
    window.location.href = '#Home';
    setClick(!click);
  }

  return (
    <div onClick={handleClick} id='Hamburger'>
      <RowBurger click={click} className='firstRow' />
      <RowBurger click={click} className='secondRow' />
      <RowBurger click={click} className='thirdRow' />
    </div>
  )
}

export default Hamburger;