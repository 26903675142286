import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

// const clickCircle = () => {
//   document.getElementById("Circle").classList.add("circleActive");
// }

const MyAppBar = () => {
  return (
    <div id="MyAppBar">
      <Navbar
        expand="lg" 
        className='bg-body-tertiary' 
        data-bs-theme="dark"
      >
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='me-auto'>
              <Nav.Link href="#Home">Home</Nav.Link>
              <Nav.Link href="#AboutPage">About</Nav.Link>
              <Nav.Link href="#SkillsPage">Skills</Nav.Link>
              <Nav.Link href="#ProjectsPage">Projects</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
  
export default MyAppBar;