import './App.css';
import TitleMessage from './components/TitleMessage';
import AppSpheres from './components/Spheres';
import MyAppBar from './components/MyAppBar';
import About from './views/about/About';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skills from './views/skills/Skills';
import Projects from './views/projects/Projects';
import Container from "react-bootstrap/Container";
import Fade from "react-awesome-reveal";
import Slide from "react-awesome-reveal";
import { useEffect, useRef, useState } from 'react'
import MenuBurger from './components/MenuBurger';
import Hamburger from './components/Hamburger';

function App() {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = (e) => {
      let scrollPosition = window.scrollY || document.documentElement.scrollTop;
      if (scrollPosition > 846) {
        //on scroll
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className='App'>
      <div>
        <div id='Home'>
          {/* <MyAppBar /> */}
          <AppSpheres />
        </div>
        <div>
          <Row>
            <Col>
              <div id='Initials'>IG</div>
            </Col>
            <Col>
              <Hamburger />
              <MenuBurger />
            </Col>
          </Row>
        </div>
        <div id="MyContent">
          <TitleMessage />
          <div style={{ height: "25vh" }} >
            {/* spacer for home page */}
          </div>
          <div>
            <svg className='arrows'>
              <path className='a1' d="M0 0 L30 32 L60 0"></path>
              <path className='a2' d="M0 20 L30 52 L60 20"></path>
              <path className='a3' d="M0 40 L30 72 L60 40"></path>
            </svg>
          </div>
          <div id="AboutPage" style={{ height: "15vh" }}>
            {/* spacer for About */}
          </div>
          <div id="About">
            <Container>
              <Fade>
                <About />
              </Fade>
            </Container>
          </div>
          <div id="SkillsPage" style={{ height: "10vh" }}>
            {/* spacer for Skills */}
          </div>
          <div id="Skills">
            <Container>
              <Fade>
                <Skills />
              </Fade>
            </Container>
          </div>
          <div id="ProjectsPage" style={{ height: "10vh" }}>
            {/* spacer for Projects */}
          </div>
          <div id="Projects">
            <Container>
              <Fade>
                <Projects />
              </Fade>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;






