import { MathUtils } from 'three'
import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Instances, Instance } from '@react-three/drei'

let lowSpeed = 0.2;
let highSpeed = 0.8;
let zPos = -10;
let numSpheres = 200;
let acc = 0;

const particles = Array.from({ length: numSpheres }, () => ({
  speed: MathUtils.randFloat(lowSpeed, highSpeed),
  xPos: MathUtils.randFloat(-100, 100),
  yPos: MathUtils.randFloat(-60, 60),
  zPos: zPos
}));

export default function AppSpheres() {
  return (
    <div id="MySpheres" style={{ width: "100vw", height: "100vh"}}>
      <Canvas camera={{ position: [0, 0, 70], aspect: window.innerWidth / window.innerHeight, fov: 120, near: 0.1, far: 100 }}>
        <ambientLight intensity={1} />
        <Spheres />
      </Canvas>
    </div>
  )
}

function Spheres() {
  const ref = useRef();
  return (
    particles.map((data, i) => {
      return (
        <Instances limit={particles.length} ref={ref} position={[particles[i].xPos, particles[i].yPos, particles[i].zPos]}>
          <sphereGeometry args={[0.2, 4, 4]} />
          <meshStandardMaterial roughness={0} color="aqua" />
          <Sphere key={i} {...data} speed={particles[i].speed} position={[particles[i].xPos, particles[i].yPos, particles[i].zPos]} />
        </Instances>
      );
    })
  );
}

function Sphere({ speed }) {
  const ref = useRef();
  useFrame((state) => {
    // if(acc < 5) {
    //   ref.current.position.set(
    //     ref.current.position.x,
    //     ref.current.position.y,
    //     ref.current.position.z + speed + acc,
    //   );
    // } else {
      // acc = 0;
      ref.current.position.set(
        ref.current.position.x,
        ref.current.position.y,
        ref.current.position.z + speed,
      );
    // }
    // acc = acc + 0.0003;
    if (ref.current.position.z > 70) {
      ref.current.position.z = zPos;
      ref.current.position.x = MathUtils.randFloat(-100, 100);
      ref.current.position.y = MathUtils.randFloat(-50, 50);
    }
  });
  return <Instance ref={ref} />
}
