import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.css";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import ProfileImg from "../../assets/irakli1.png";
import Typewriter from "typewriter-effect";

const About = () => {
  return (
    <div>
      <div style={{marginBottom: "50px"}}>
        <h1>About Me</h1>
      </div>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <Image roundedCircle width="50%" src={ProfileImg} alt="profile" />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <div id="Paragraphs">
                {/* <Typewriter
                  onInit={(typewriter) => {
                    typewriter.changeDelay(75).typeString("Dedicated, hard-working, and curious Software Developer. ").pauseFor(2000).typeString("Over 7 years of experience in total. ")
                      .pauseFor(2000).typeString("Passionate about learning and applying next-generation technologies. ").pauseFor(2000).typeString("Eager to make an impact. ")
                      .pauseFor(2000).start();
                  }}
                /> */}
                Dedicated, hard-working, and curious Software Developer. Over 7 years of experience in total. 
                Passionate about learning and applying next-generation technologies. Eager to make an impact. 
                <br/>
                <br/>
                Skilled in backend web application development utilizing C# for API's and services. Experience 
                working on SQL database migrations utilizing a code-first approach using MSSQL Server and Entity Framework.
                Some exposure to frontend development with experience in Vue.js and React. Spent time with Electron to 
                develop desktop applications and Flutter for cross-platform mobile app development.
              </div>
            </Row> 
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default About;