import React, { useEffect, useRef } from 'react';

const RowBurger = ({click, className}) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.classList.add(className);
  }, [click])

  return (
    <div ref={ref} className='rowBurger'>
    </div>
  );
}

export default RowBurger;