import Carousel from 'react-bootstrap/Carousel';
import discordbot1 from "../../assets/discordbot1.png";
import discordbot2 from "../../assets/discordbot2.png";
import discordbot3 from "../../assets/discordbot3.png";

import Image from "react-bootstrap/Image";

const Projects = () => {
    return (
      <div>
        <div id='MyProjects'>
          <h1>My Projects</h1>
        </div>
        <div>
          <h3>Discord Trading Bot</h3>
          <p>A paper trading discord bot that you can use to trade stocks.</p>
        </div>
        <div>
          <Carousel>
            <Carousel.Item interval={3000}>
              <Image className='carousel-image-style' src={discordbot1} alt='Slide One' />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <Image className='carousel-image-style' src={discordbot2} alt='Slide Two' />
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <Image className='carousel-image-style' src={discordbot3} alt='Slide Three' />
            </Carousel.Item>
          </Carousel>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    )
  }
  
  export default Projects;