import styled from "styled-components";
import Typewriter from "typewriter-effect";

const MyTitleMessage = styled.h1`
  position: relative;
  color: aqua;
  font-size: 24px;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  top: 300px;
  height: 600px;
`;

const TitleMessage = () => (
  <MyTitleMessage>
    <div>
      <div>
        <div id="TitleMessage">
          Hello, I am
          <br/>
          <span><strong>Irakli Gvino</strong></span>
        </div>
        <div>
          <div id="Divider">
            <hr/>
          </div>
        <Typewriter 
          options={{
            strings: ["Software Developer", "Tech Enthusiast", "Gamer"],
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 30,
            pauseFor: 2000
          }}
        />
        </div>
      </div>
      
    </div>
  </MyTitleMessage>
)

export default TitleMessage;